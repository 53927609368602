import type { ComponentType } from "react"
import {
    motion,
    motionValue,
    useMotionValue,
    useTransform,
    useSpring,
} from "framer-motion"

const x = motionValue(0.5)
const y = motionValue(0.5)

export function Main(Component): ComponentType {
    return (props) => {
        const handleMouseMove = (event) => {
            const rect = event.currentTarget.getBoundingClientRect()
            const mouseX = event.clientX - rect.left
            const mouseY = event.clientY - rect.top

            x.set(mouseX / rect.width)
            y.set(mouseY / rect.height)
        }
        return (
            <Component
                {...props}
                onMouseMove={handleMouseMove}
                onMouseLeave={() => {
                    x.set(0.5)
                    y.set(0.5)
                }}
            />
        )
    }
}

export function Child1(Component): ComponentType {
    return (props) => {
        const translateX = useTransform(x, [0, 1], [-30, 30])
        const translateY = useTransform(y, [0, 1], [-30, 30])

        const animatedX = useSpring(translateX, {
            stiffness: 200,
            damping: 30,
        })
        const animatedY = useSpring(translateY, {
            stiffness: 200,
            damping: 30,
        })
        return (
            <Component
                {...props}
                // as={motion.div}
                style={{
                    ...props.style,
                    translateX: animatedX,
                    translateY: animatedY,
                    transformOrigin: "center",
                }}
            />
        )
    }
}

export function Child2(Component): ComponentType {
    return (props) => {
        const translateX = useTransform(x, [0, 1], [-50, 50])
        const translateY = useTransform(y, [0, 1], [-50, 50])

        const animatedX = useSpring(translateX, {
            stiffness: 200,
            damping: 30,
        })
        const animatedY = useSpring(translateY, {
            stiffness: 200,
            damping: 30,
        })
        return (
            <Component
                {...props}
                // as={motion.div}
                style={{
                    ...props.style,
                    translateX: animatedX,
                    translateY: animatedY,
                    transformOrigin: "center",
                }}
            />
        )
    }
}

export function Child3(Component): ComponentType {
    return (props) => {
        const translateX = useTransform(x, [0, 1], [-60, 60])
        const translateY = useTransform(y, [0, 1], [-60, 60])

        const animatedX = useSpring(translateX, {
            stiffness: 200,
            damping: 30,
        })
        const animatedY = useSpring(translateY, {
            stiffness: 200,
            damping: 30,
        })
        return (
            <Component
                {...props}
                // as={motion.div}
                style={{
                    ...props.style,
                    translateX: animatedX,
                    translateY: animatedY,
                    transformOrigin: "center",
                }}
            />
        )
    }
}

export function Child4(Component): ComponentType {
    return (props) => {
        const translateX = useTransform(x, [0, 1], [-70, 70])
        const translateY = useTransform(y, [0, 1], [-70, 70])

        const animatedX = useSpring(translateX, {
            stiffness: 200,
            damping: 30,
        })
        const animatedY = useSpring(translateY, {
            stiffness: 200,
            damping: 30,
        })
        return (
            <Component
                {...props}
                // as={motion.div}
                style={{
                    ...props.style,
                    translateX: animatedX,
                    translateY: animatedY,
                    transformOrigin: "center",
                }}
            />
        )
    }
}
